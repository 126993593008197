import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Grid2 } from "@mui/material";
import { openNewTab } from "../../ext/WindowExtensions";
import { ButtonStyle, RoundedButtonStyle } from "../../../theme/ButtonStyles";
import { changeTitle } from "../../state/titleSlicer";
import { programsTitle } from "../TitlePages";
import { GridCardImage } from "../../cards/GridCardImage";
import {
  AppleImage,
  DeepvokeImage,
  FIICompareImage,
  WindowsImage,
} from "../../../images/Images";

const Styles = {
  container: {
    marginTop: "32px",
    marginLeft: "32px",
    marginBottom: "32px",
    marginRight: "32px",
  },
  buttonsCotainer: {
    display: "flex",
    flexDirection: "row",
  },
  buttonSize: {
    height: "18px",
    width: "18px",
  },
  buttonSpacing: {
    marginRight: "8px",
  },
  buttonPadding: {
    padding: "8px",
  },
};

const ButtonsContainer = () => {
  return (
    <div style={Styles.buttonsCotainer}>
      <Button
        onClick={() => {
          openNewTab(
            "https://drive.google.com/file/d/1AkjWtSscheCxxV_QYHY6I0GSSLR5100o/view?usp=drive_link"
          );
        }}
        style={{ ...Styles.buttonSpacing, ...Styles.buttonPadding }}
        variant="outlined"
        sx={RoundedButtonStyle}
      >
        <img style={Styles.buttonSize} src={WindowsImage} alt=""></img>
      </Button>
      <Button
        onClick={() => {
          openNewTab(
            "https://drive.google.com/file/d/1Dmo_aNkOn_ISoOsd_Hlju2BxExFzUn4n/view?usp=drive_link"
          );
        }}
        style={Styles.buttonPadding}
        variant="outlined"
        sx={RoundedButtonStyle}
      >
        <img style={Styles.buttonSize} src={AppleImage} alt=""></img>
      </Button>
    </div>
  );
};

export default function ProgramsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeTitle(programsTitle));
  });

  return (
    <div style={Styles.container}>
      <Grid2 container spacing={{ xs: 2, md: 3 }}>
        <GridCardImage
          title={"FII Compare"}
          description={
            "Programa para realizar a filtragem e a análise fundamentalista de fundos imobiliários. "
          }
          image={FIICompareImage}
          navComponent={<ButtonsContainer />}
        />
        <GridCardImage
          title={"Deepvoke"}
          description={
            "Funcionalidade web para a invocação de deeplinks customizados de aplicações mobile."
          }
          image={DeepvokeImage}
          navComponent={
            <Button
              variant="outlined"
              sx={ButtonStyle}
              onClick={() => {
                navigate("/deepvoke");
              }}
            >
              Acessar
            </Button>
          }
        />
      </Grid2>
    </div>
  );
}
