import { useDispatch } from "react-redux";
import { changeTitle } from "../../state/titleSlicer";
import { useEffect } from "react";
import PtaxContext from "../../features/ptax/PtaxContext";

const Styles = {
  container: {
    marginTop: "32px",
    marginLeft: "32px",
    marginBottom: "32px",
    marginRight: "32px",
  },
};

const PtaxPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeTitle("PTAX"));
  });

  return (
    <div style={Styles.container}>
      <PtaxContext />
    </div>
  );
};

export default PtaxPage;
