/**
 * Transform a number to a string with a fixed number of decimals.
 */
export function transformToBrazilNumber(value) {
  return value.toLocaleString("pt-BR", {
    style: "decimal",
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  });
}
