import { useDispatch } from "react-redux";
import DeeplinkContext from "../../features/deeplink/DeeplinkContext";
import { changeTitle } from "../../state/titleSlicer";
import { useEffect } from "react";

const Styles = {
  container: {
    marginTop: "32px",
    marginLeft: "32px",
    marginBottom: "32px",
    marginRight: "32px",
  },
};

const DeepvokePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeTitle("Deepvoke"));
  });

  return (
    <div style={Styles.container}>
      <DeeplinkContext />
    </div>
  );
};

export default DeepvokePage;
