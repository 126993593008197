import { useDispatch } from "react-redux";
import { changeTitle } from "../../state/titleSlicer";
import { calculatorTitle } from "../TitlePages";
import { useEffect, useState } from "react";
import { GridCardImage } from "../../cards/GridCardImage";
import { Button, Grid2 } from "@mui/material";
import { ButtonStyle } from "../../../theme/ButtonStyles";
import WiseWebScrappingScriptDialog from "../../investments/scripts/WiseWebScrappingScriptDialog";
import { PtaxImage, WiseCardImage } from "../../../images/Images";
import { useNavigate } from "react-router-dom";

const Styles = {
  container: {
    marginTop: "32px",
    marginLeft: "32px",
    marginBottom: "32px",
    marginRight: "32px",
  },
};

export default function InvestmentsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [wiseWebScrappingScriptDialog, setWiseWebScrappingScriptDialog] =
    useState(false);

  useEffect(() => {
    dispatch(changeTitle(calculatorTitle));
  });

  return (
    <div style={Styles.container}>
      <Grid2 container spacing={{ xs: 2, md: 3 }}>
        <GridCardImage
          title={"Google Planilhas - Preço do dólar Wise"}
          description={
            "Realize a integração entre o Google Planilhas e o site da Wise para consultar a cotação do dólar."
          }
          image={WiseCardImage}
          navComponent={
            <div>
              <Button
                onClick={() => {
                  setWiseWebScrappingScriptDialog(true);
                }}
                variant="outlined"
                sx={ButtonStyle}
              >
                Script
              </Button>
              <WiseWebScrappingScriptDialog
                open={wiseWebScrappingScriptDialog}
                handleClose={() => setWiseWebScrappingScriptDialog(false)}
              />
            </div>
          }
        />
        <GridCardImage
          title={"Consulta dólar PTAX - Banco Central"}
          description={
            "Consulte o preço de fechamento do dólar PTAX para uma ou mais datas específicas."
          }
          image={PtaxImage}
          navComponent={
            <div>
              <Button
                onClick={() => {
                  navigate("/investments/ptax");
                }}
                variant="outlined"
                sx={ButtonStyle}
              >
                Consulta
              </Button>
            </div>
          }
        />
      </Grid2>
    </div>
  );
}
