import { useEffect, useRef, useState } from "react";
import { articlesTitle } from "./TitlePages";
import { changeTitle } from "../state/titleSlicer";
import { useDispatch } from "react-redux";
import getPosts from "../../services/MediumService";
import GridCardButton from "../cards/GridCardButton";
import { CircularProgress, Grid2 } from "@mui/material";

const Styles = {
  container: {
    marginTop: "32px",
    marginLeft: "32px",
    marginBottom: "32px",
    marginRight: "32px",
  },
};

export default function ArticlesPage(props) {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const initialized = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeTitle(articlesTitle));

    if (!initialized.current) {
      initialized.current = true;

      setIsLoading(true);
      getPosts()
        .then((response) => {
          setPosts(response);
          setIsLoading(false);
        })
        .catch((err) => {
          return;
        });
    }
  }, [dispatch]);

  const Loading = () => {
    return <CircularProgress style={{ color: "#000000" }} />;
  };

  return (
    <div style={Styles.container}>
      {isLoading ? (
        <Loading />
      ) : (
        <Grid2 container spacing={{ xs: 2, md: 3 }}>
          {posts.map((post) => (
            <GridCardButton
              title={post.title}
              image={post.image}
              link={post.link}
            />
          ))}
        </Grid2>
      )}
    </div>
  );
}
