import * as React from "react";
import * as XLSX from "xlsx";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import PtaxAllDataTable from "./PtaxAllDataTable";
import { transformToBrazilNumber } from "../../../utils/NumericUtils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const transformData = (data) => {
  return data.map((item) => ({
    Data: item.date,
    Compra: transformToBrazilNumber(item.buyPrice),
    Venda: transformToBrazilNumber(item.sellPrice),
  }));
};

export default function PtaxAllDataDialog(props) {
  const allQuotes = props.allQuotes;
  const handleExport = () => {
    const data = transformData(allQuotes);
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "cotacoes.xlsx");
  };

  return (
    <BootstrapDialog
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Cotações
      </DialogTitle>
      <DialogContent dividers>
        <PtaxAllDataTable allQuotes={allQuotes} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleExport}>
          Salvar em planilha
        </Button>
        <Button autoFocus onClick={props.handleClose}>
          Fechar
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
