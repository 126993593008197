import axios from "axios";

const BASE_URL = "https://open-wallet-apis-idzs.onrender.com/";

/**
 * Axios instance with Open Wallet API base URL.
 */
export const axiosOpenWallet = axios.create({
  baseURL: BASE_URL,
});
