import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { IconButton, Snackbar } from "@mui/material";
import { useState } from "react";
import { transformToBrazilNumber } from "../../../utils/NumericUtils";

const CopyToCliboardSnackbar = (props) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      message={"O valor " + props.text + " foi copiado!"}
      autoHideDuration={2000}
      onClose={props.handleClose}
      open={props.showCopySuccess}
    />
  );
};

export default function PtaxAllDataTable(props) {
  const allQuotes = props.allQuotes;

  const [copySuccess, setCopySuccess] = useState("");
  const [showCopySnackbar, setShowCopySnackbar] = useState(false);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopySuccess(text);
        setShowCopySnackbar(true);
      },
      (err) => {
        setCopySuccess("Erro ao copiar o text");
      }
    );
  };

  return (
    <div className="global-font">
      <TableContainer component={Paper} elevation={0}>
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Data</TableCell>
              <TableCell align="right">Compra</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">Venda</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allQuotes.map((row) => (
              <TableRow
                key={row.date}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.date}
                </TableCell>
                <TableCell align="right">
                  R$ {transformToBrazilNumber(row.buyPrice)}
                </TableCell>
                <TableCell align="right">
                  <div>
                    <IconButton
                      onClick={() => {
                        handleCopy(transformToBrazilNumber(row.buyPrice));
                      }}
                    >
                      <FileCopyOutlinedIcon />
                    </IconButton>
                  </div>
                </TableCell>
                <TableCell align="right">
                  R$ {transformToBrazilNumber(row.sellPrice)}
                </TableCell>
                <TableCell align="right">
                  <div>
                    <IconButton
                      onClick={() => {
                        handleCopy(transformToBrazilNumber(row.sellPrice));
                      }}
                    >
                      <FileCopyOutlinedIcon />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CopyToCliboardSnackbar
        showCopySuccess={showCopySnackbar}
        text={copySuccess}
        handleClose={() => {
          setShowCopySnackbar(false);
        }}
      />
    </div>
  );
}
