import { Routes, Route, Navigate } from "react-router-dom";
import BooksPage from "../pages/BooksPage";
import PageContainer from "../principal/PageContainer";
import InvestmentsPage from "../pages/investments/InvestmentsPage";
import ProgramsPage from "../pages/programs/ProgramsPage";
import PresentationPage from "../pages/PresentationPage";
import ArticlesPage from "../pages/ArticlesPage";
import DeepvokePage from "../pages/programs/DeepvokePage";
import PtaxPage from "../pages/investments/PtaxPage";

const RouterContainer = () => {
  return (
    <Routes>
      <Route element={<PageContainer />}>
        <Route path="/" exact element={<PresentationPage />} />
        <Route path="/livros" exact element={<BooksPage />} />
        <Route path="/programas" exact element={<ProgramsPage />} />
        <Route path="/investimentos" exact element={<InvestmentsPage />} />
        <Route path="/artigos" exact element={<ArticlesPage />} />
        <Route path="/deepvoke" exact element={<DeepvokePage />} />
        <Route path="/investments/ptax" exact element={<PtaxPage />} />
        <Route path="*" exact element={<Navigate to="/" replace />} />
      </Route>
      <Route path="*" exact element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default RouterContainer;
