import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Button,
  Chip,
  Snackbar,
  SnackbarContent,
  CircularProgress,
  Link,
} from "@mui/material";
import { useEffect, useState } from "react";
import { axiosOpenWallet } from "../../../services/OpenWalletService";
import PtaxAllDataDialog from "./PtaxAllDataDialog";

const Style = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    minHeight: "70vh",
  },
  containerButtons: {
    display: "flex",
    gap: "8px",
    marginTop: "32px",
  },
  containerChips: {
    marginTop: "32px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
  chipsItem: {
    margin: "8px",
  },
};

const ChipList = (props) => {
  const chipData = props.chipData;
  const handleDelete = props.handleDelete;

  if (chipData == null) {
    return <div></div>;
  } else {
    return chipData.map((data) => {
      return (
        <Chip
          style={Style.chipsItem}
          label={data.label}
          onDelete={handleDelete(data)}
        />
      );
    });
  }
};

const ServerSnackBarError = (props) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={props.showServerError}
    >
      <SnackbarContent
        message="Houve um erro ao iniciar o servidor"
        style={{ backgroundColor: "#c62828", color: "white" }}
        action={
          <div>
            <Button
              variant="contained"
              sx={{ color: "black" }}
              style={{ backgroundColor: "white" }}
              size="small"
              onClick={props.handleTryAgain}
            >
              Tentar novamente
            </Button>
          </div>
        }
      />
    </Snackbar>
  );
};

const ServerStartingSnackBar = (props) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={!props.isServerDone}
    >
      <SnackbarContent
        message="O servidor está sendo inciado aguarde..."
        action={<CircularProgress style={{ padding: "8px", color: "white" }} />}
      />
    </Snackbar>
  );
};

const FetchQuotesErrorSnackBar = (props) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      message="Houve um erro. Verifique se as datas correspondem a dias úteis com fechamento de mercado."
      autoHideDuration={5000}
      onClose={props.handleClose}
      open={props.showFetchError}
    />
  );
};

const PtaxContext = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [chipData, setChipData] = useState(null);
  const [isServerDone, setIsServerDone] = useState(false);
  const [isSeaching, setIsSearching] = useState(false);
  const [showServerError, setShowServerError] = useState(false);
  const [showFetchError, setShowFetchError] = useState(false);
  const [showFetchedPtax, setShowFetchedPtax] = useState(false);
  const [allPtaxData, setAllPtaxData] = useState({});

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const handleClosePtaxDialog = () => {
    setShowFetchedPtax(false);
  };

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };

  const handleTryAgain = async () => {
    fetchPing();
  };

  const fetchPing = async () => {
    setIsServerDone(false);
    setShowServerError(false);

    axiosOpenWallet
      .get("/ping")
      .then((response) => {
        setIsServerDone(true);
      })
      .catch((err) => {
        setIsServerDone(true);
        setShowServerError(true);
      });
  };

  const fetchAllPtaxData = async () => {
    const allDates = chipData.map((chip) => chip.key);
    const request = {
      dates: allDates,
    };

    setShowFetchError(false);
    setIsSearching(true);

    axiosOpenWallet
      .post("ptax/all", request)
      .then((response) => {
        setIsSearching(false);
        setShowFetchedPtax(true);
        setAllPtaxData(response.data);
      })
      .catch((err) => {
        setIsSearching(false);
        setShowFetchError(true);
      });
  };

  useEffect(() => {
    fetchPing();
  }, []);

  const handleAddDate = () => {
    if (selectedDate != null) {
      const newDate = selectedDate.format("DD/MM/YYYY");

      if (chipData !== null) {
        const exists = chipData.some((chip) => chip.key === newDate);

        if (!exists) {
          setChipData([...chipData, { key: newDate, label: newDate }]); // Add new date if it doesn't exist
        }
      } else {
        setChipData([{ key: newDate, label: newDate }]);
      }
    }
  };

  const isChipDataEmpty = () => {
    return chipData === null || chipData.length === 0;
  };

  return (
    <div className="global-font-div">
      <div style={Style.container}>
        <h1>Dólar PTAX</h1>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD/MM/YYYY"
              label="Data"
              onChange={handleDateChange}
            />
          </LocalizationProvider>
        </div>
        <div style={Style.containerButtons}>
          <Button onClick={handleAddDate} variant="contained">
            Adicionar
          </Button>
          <Button
            loading={!isServerDone || isSeaching}
            disabled={
              !isServerDone ||
              showServerError ||
              isChipDataEmpty() ||
              isSeaching
            }
            loadingPosition="center"
            variant="contained"
            onClick={fetchAllPtaxData}
          >
            Pesquisar
          </Button>
        </div>
        <div style={Style.containerChips}>
          {<ChipList chipData={chipData} handleDelete={handleDelete} />}
        </div>
        <ServerStartingSnackBar isServerDone={isServerDone} />
        <ServerSnackBarError
          showServerError={showServerError}
          handleTryAgain={handleTryAgain}
        />
        <FetchQuotesErrorSnackBar
          showFetchError={showFetchError}
          handleClose={() => setShowFetchError(false)}
        />
        <PtaxAllDataDialog
          open={showFetchedPtax}
          handleClose={handleClosePtaxDialog}
          allQuotes={allPtaxData.allDates}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Link
          underline="none"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.bcb.gov.br/conteudo/relatorioinflacao/EstudosEspeciais/EE042_A_taxa_de_cambio_de_referencia_Ptax.pdf"
        >
          O que é PTAX?
        </Link>
        <span style={{ margin: "0 10px" }}>|</span>
        <Link
          underline="none"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.bcb.gov.br/estabilidadefinanceira/historicocotacoes"
        >
          Site oficial
        </Link>
      </div>
    </div>
  );
};

export default PtaxContext;
