const requireImage = (image) => require("../assets/" + image);

export const PortoImage = requireImage("porto.png");
export const ToastmastersImage = requireImage("toastmasters.png");
export const Toastmasters2Image = requireImage("toastmasters2.png");
export const UdemyImage = requireImage("udemy.png");
export const RogaDXImage = requireImage("roga.png");
export const FacimaImage = requireImage("facima.png");
export const VentuirImage = requireImage("ventuir.png");
export const YoutubeImage = requireImage("youtube.png");
export const OatImage = requireImage("oat.png");
export const HuaweiImage = requireImage("huawei.png");
export const IfalImage = requireImage("ifal.png");
export const GdgImage = requireImage("gdg.png");
export const GoogleImage = requireImage("google.png");
export const Google2Image = requireImage("google2.png");
export const Unit2Image = requireImage("unit2.png");
export const SenaiImage = requireImage("senai.png");
export const JuniorAchievementImage = requireImage("juniorachievement.png");
export const FllImage = requireImage("fll.png");
export const CaiiteImage = requireImage("caiite.png");
export const StanboroughSchoolImage = requireImage("stanboroughschool.png");
export const UsBrazilConnectImage = requireImage("usbrazilconnect.png");
export const BrazilImage = requireImage("brazil.png");
export const Data4GoodImage = requireImage("data4good.png");
export const LarSaoDomingosImage = requireImage("larsaodomingos.png");
export const PicPayImage = requireImage("picpay.jpeg");
export const ZupImage = requireImage("zupinnovation.jpeg");
export const HandTalkImage = requireImage("handtalk.jpeg");
export const StantImage = requireImage("stant.jpeg");
export const BwnImage = requireImage("bwn.png");
export const LeituraImage = requireImage("leitura.png");
export const SuprifitasImage = requireImage("suprifitas.png");
export const MediumImage = requireImage("medium.png");
export const Book2Image = requireImage("book2.png");
export const FatmImage = requireImage("fatm.png");
export const InternetImage = requireImage("internet.png");
export const JavaImage = requireImage("java.png");
export const ObrImage = requireImage("obr.png");
export const EstacioImage = requireImage("estacio.png");
export const CadaMinutoImage = requireImage("cadaminuto.png");
export const AppleImage = requireImage("apple.png");
export const WindowsImage = requireImage("windows.png");
export const FIICompareImage = requireImage("fii_compare.png");
export const DeepvokeImage = requireImage("deepvoke.png");
export const DollarImage = requireImage("dollar.png");

// Cards images
export const WiseCardImage = require("./cards/wiseCardImage.png");

// Ptax images
export const PtaxImage = require("./ptax/ptax_cover.png");

// Wise images
export const WiseStepOne = require("./wise/step1.png");
export const WiseStepTwo = require("./wise/step2.png");
export const WiseStepThree = require("./wise/step3.png");
export const WiseStepFour = require("./wise/step4.png");
export const WiseStepFive = require("./wise/step5.png");
