import axios from "axios";

const MEDIUM_URL =
  "https://api.rss2json.com/v1/api.json?rss_url=http%3A%2F%2Fmedium.com%2Ffeed%2F%40gabriel.brasileiro";

const START_SLICE = 0;
const NEGATIVE_FOR_SLICING = 1;
const END_SLICE_SYBOL = "><";
const START_SLICE_SYBOL = "https";

export default function getPosts() {
  return new Promise((resolve, reject) => {
    axios
      .get(MEDIUM_URL)
      .then((info) => {
        const blogs = info.data.items;
        const allPosts = blogs.map((post) => {
          const contentLenght = post.content.lenght;
          const sliceStartPos = post.content.indexOf(START_SLICE_SYBOL);
          const afterSliceTags = post.content.slice(
            sliceStartPos,
            contentLenght
          );
          const indexOfEnd =
            afterSliceTags.indexOf(END_SLICE_SYBOL) - NEGATIVE_FOR_SLICING;
          const imageContent = afterSliceTags.slice(START_SLICE, indexOfEnd);

          return {
            title: post.title,
            link: post.link,
            image: imageContent,
          };
        });

        resolve(allPosts);
      })
      .catch((err) => reject(err));
  });
}
