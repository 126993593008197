import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import LinkIcon from "@mui/icons-material/Link";
import DeeplinkManuallyQueryDialog from "./DeeplinkManuallyQuery";
import arrayToQueryParameters from "../../../utils/ArrayToQuery";

const Style = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    minHeight: "70vh",
  },
  textField: {
    width: "90%",
    marginLeft: "24px",
    marginRight: "24px",
    marginTop: "24px",
  },
  queryButtonStyle: {
    marginTop: "16px",
  },
  previewDeeplinkContainer: {
    marginTop: "32px",
  },
  queryStyle: {
    marginTop: "16px",
  },
  buttonContainer: {
    marginTop: "16px",
  },
  bottomInfo: {
    marginTop: "32px",
  },
  title: {
    marginTop: "4px",
    fontWeight: 400,
    fontSize: "30px",
  },
  titleIcon: {
    fontSize: 40,
    padding: 0,
    minHeight: 0,
    minWidth: 0,
  },
};

const DeeplinkContext = () => {
  const [currentDeeplink, setCurrentDeeplink] = useState("");
  const [currentDeeplinkQuery, setCurrentDeeplinkQuery] = useState("");
  const [mQueryDialog, setMQueryDialog] = useState(false);
  const [currentQueryParams, setCurrentQueryParams] = useState([]);

  function handleOpen() {
    setMQueryDialog(true);
  }

  function handleClose() {
    setMQueryDialog(false);
  }

  function handleQueryParameters(queries) {
    const allQuery = arrayToQueryParameters(queries);

    setCurrentQueryParams(queries);
    setCurrentDeeplinkQuery(allQuery);
  }

  return (
    <div>
      <div style={Style.global}>
        <div style={Style.container}>
          <LinkIcon style={Style.titleIcon} fontSize="large" />
          <h1 style={Style.title} className="global-font">
            Deepvoke
          </h1>
          <TextField
            id="outlined-basic"
            style={Style.textField}
            label="Deeplink"
            variant="outlined"
            onChange={(event) => {
              setCurrentDeeplink(event.target.value);
            }}
          />
          <h5 style={Style.queryStyle} className="global-font break-word">
            {currentDeeplink + currentDeeplinkQuery}
          </h5>
          <Button
            style={Style.queryButtonStyle}
            variant="outlined"
            onClick={() => handleOpen()}
          >
            MQuery
          </Button>
          <Button
            style={Style.buttonContainer}
            variant="contained"
            href={currentDeeplink + currentDeeplinkQuery}
          >
            Launch deeplink
          </Button>
          <div style={Style.bottomInfo} className="global-font">
            By Gabriel Brasileiro
          </div>
          <div className="global-font">1.0.1</div>
        </div>
      </div>
      <DeeplinkManuallyQueryDialog
        openDialog={mQueryDialog}
        handleClose={handleClose}
        currentQueryParameters={currentQueryParams}
        handleQueryParameters={(queries) => handleQueryParameters(queries)}
      />
    </div>
  );
};

export default DeeplinkContext;
